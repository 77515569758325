<template>
    <path d="m268 416h24c6.6 0 12-5.4 12-12v-216c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v216c0 6.6 5.4 12 12 12zm164-336h-82.4l-34-56.7c-8.7-14.5-24.3-23.3-41.2-23.3h-100.8c-16.9 0-32.5 8.8-41.2 23.3l-34 56.7h-82.4c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16v336c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-336h16c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-260.2-29.1c1.1-1.8 3-2.9 5.2-2.9h94c2.1 0 4.1 1.1 5.1 2.9l17.5 29.1h-139.2l17.4-29.1zm196.2 413.1h-288v-336h288v336zm-212-48h24c6.6 0 12-5.4 12-12v-216c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v216c0 6.6 5.4 12 12 12z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 448, height: 512 });
    },
    emits: ['dimensions'],
};
</script>
